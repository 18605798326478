import React from "react"
import { graphql } from "gatsby"

import { Sections as Template } from "../components/Sections/Sections"

export const query = graphql`
  query ($id: String!) {
    page: sanityPageFlexible(_id: { eq: $id }) {
      title
      slug {
        current
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      content: _rawContent(resolveReferences: { maxDepth: 6 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Template {...props} {...data} breadcrumbs />

export default Component
